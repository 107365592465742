<template>
    <vs-sidebar background="primary" textWhite absolute v-model="active" open>
        <vs-sidebar-item id="Notifications" to="/admin/notifications/all">
            <template #icon>
                <i class="bx bxs-bell-ring"></i>
            </template>
            Thông báo
        </vs-sidebar-item>
        <vs-sidebar-item id="Ratings" to="/admin/ratings">
            <template #icon>
                <i class="bx bx-upvote"></i>
            </template>
            Đánh giá
        </vs-sidebar-item>
        <vs-sidebar-item id="Logout" >
            <template #icon>
                <i class="bx bx-log-out"></i>
            </template>
            Đăng xuất
        </vs-sidebar-item>
    </vs-sidebar>
</template>
      <script>
export default {
    data() {
        return {
            active: this.$router.history.current.name,
        };
    },
    watch: {
      active(value){
        if(value === 'Logout'){
            this.$store.dispatch("logout");
            console.log("click logout");
        }
      }
    }
};
</script>