<template>
    <div>
        <navbar />
        <div style="padding-left: 260px;">
            <div class="p-2">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from '../shareds/admin/Navbar.vue';
export default {
    components: { Navbar },
    data(){
        return {};
    }
}
</script>